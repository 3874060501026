import React from 'react';
import PropTypes from "prop-types";
import I18n from "i18n-react";
import ConfigService from '../services/ConfigService';
import ImageHeader from './ImageHeader';
import Logger from '../services/LoggingService';

const IDSTATE = Object.freeze({
    OK: "ok",
    INVALID: "invalid",
    NOT_FOUND: "not_found",
    TO_EARLY: "to_early",
    TO_LATE: "to_late"
});

export default class StartingPage extends React.Component {

    static get propTypes() {
        return {
            "onStart": PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        let error = IDSTATE.OK;
        if (props.error) {
            error = props.error.message;
        }

        this.state = {
            termsAccepted: props.loginData.termsAccepted,
            terminnr: props.loginData.terminnr,
            firstname: props.loginData.firstname,
            lastname: props.loginData.lastname,
            region: props.loginData.region,
            error: error
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTerms = this.handleChangeTerms.bind(this);
        this.validateTerminNr = this.validateTerminNr.bind(this);
        this.handleChangeTerminNr = this.handleChangeTerminNr.bind(this);
        this.getTerminNrError = this.getTerminNrError.bind(this);
        this.handleMediaCheck = this.handleMediaCheck.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);

        if (this.state.terminnr) {
            let error = this.validateTerminNr(this.state.terminnr) ? IDSTATE.OK : IDSTATE.INVALID;
            if (this.state.error === IDSTATE.OK) {
                this.state.error = error;
            }
        }
    }

    componentDidMount() {
        document.title = I18n.translate("title");
    }

    handleSubmit() {
        let { terminnr, firstname, lastname, termsAccepted, region } = this.state;
        this.props.onStart({ terminnr, firstname, lastname, termsAccepted, region });
    }

    handleMediaCheck() {
        let { terminnr, firstname, lastname, termsAccepted, region } = this.state;
        this.props.onMediaCheck({ terminnr, firstname, lastname, termsAccepted, region });
    }

    handleChange(key) {
        return (event => {
            this.setState({
                [key]: event.target.value
            });
        }).bind(this);
    }

    handleChangeTerms(event) {
        this.setState({
            termsAccepted: event.target.checked
        });
    }

    handleChangeTerminNr(event) {
        let terminnr = event.target.value;
        let error = this.validateTerminNr(terminnr) ? IDSTATE.OK : IDSTATE.INVALID;
        this.setState({
            terminnr,
            error: error
        });
    }

    handleRegionChange(event) {
        this.setState({
            region: event.target.value
        })
    }

    validateTerminNr(terminnr) {
        let regex = new RegExp(ConfigService.config.validation.terminnr);
        return regex.test(terminnr);
    }


    isIdError(error) {
        return Object.values(IDSTATE).includes(error);
    }

    getTerminNrError() {
        if (!this.state.terminnr || this.state.error === IDSTATE.OK || !this.isIdError(this.state.error)) {
            return <div className="form-error"></div>;
        } else {
            return <div className="form-error"><span id="terminError"> {I18n.translate(`form_error_termin_${this.state.error}`).replace("$bufferStart", ConfigService.config.bufferStart)}</span></div>
        }
    }

    getError() {
        if (!this.state.error || this.isIdError(this.state.error)) {
            return <div className="form-error"></div>;
        } else {
            return <div className="form-error"><span id="fcommonError"> {I18n.translate(`form_error_common`)}</span></div>
        }
    }

    render() {
        let filledIn = this.state.termsAccepted && this.state.firstname && this.state.lastname && this.state.terminnr;
        let canSubmit = filledIn && (this.state.error === IDSTATE.OK || this.state.error === IDSTATE.TO_EARLY || !this.isIdError(this.state.error));

        let buttonToolTipp = I18n.translate(canSubmit ? "form_submit" : "form_error_unfilled");

        return (
            <div id="form" className="form">
                <ImageHeader />
                <div id="form-content">
                    <div className="header"><h1>{I18n.translate("form_intro")}</h1></div>
                    <div id="form-survey">
                        <fieldset title={I18n.translate("form_fieldset_data")}>
                            <legend>{I18n.translate("form_fieldset_data")}</legend>
                            <div className="form-group">
                                <label htmlFor="firstname">{I18n.translate("form_firstname")}</label>
                                <input id="firstname" value={this.state.firstname} placeholder={I18n.translate("form_required")} onChange={this.handleChange("firstname")} name="firstname" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastname">{I18n.translate("form_lastname")}</label>
                                <input id="lastname" value={this.state.lastname} placeholder={I18n.translate("form_required")} onChange={this.handleChange("lastname")} name="lastname" type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="terminnr">{I18n.translate("form_terminnr")}</label>
                                <input id="terminnr" value={this.state.terminnr} placeholder={I18n.translate("form_required")} onChange={this.handleChangeTerminNr} name="terminnr" type="text" className="form-control" />
                                {this.getTerminNrError()}
                            </div>
                        </fieldset>
                        <fieldset title={I18n.translate("form_fieldset_terms")}>
                            <legend>{I18n.translate("form_fieldset_terms")}</legend>
                            {I18n.translate("form_terms_text")}
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="checkboxContainer">
                                            <input id="termsAccepted" checked={this.state.termsAccepted} onChange={this.handleChangeTerms} name="termsAccepted" type="checkbox" />
                                        </td>
                                        <td>
                                            <label htmlFor="termsAccepted">
                                                {I18n.translate("form_terms_checkbox")}
                                                <a href="#/tac" rel="noopener noreferrer" target="_blank" >{I18n.translate("form_terms_link")}</a>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>

                        {this.getError()}

                        <div className="form-survey-submit button-container">
                            <button id="start" onClick={this.handleSubmit} disabled={!canSubmit} className="btn btn-default" title={buttonToolTipp} >{I18n.translate("form_submit")}</button>
                            <button id="media_check" onClick={this.handleMediaCheck} className="btn btn-default" title={I18n.translate("form_media_check")} >{I18n.translate("form_media_check")}</button>
                        </div>
                        <span className="notice">{I18n.translate("from_notice")}</span>
                    </div>
                </div>
            </div>
        );
    }
}
