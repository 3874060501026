import I18n from "i18n-react";
import React from "react";
import ImageHeader from './ImageHeader';

class Tac extends React.Component {

    componentDidMount() {
        document.title = I18n.translate("title_tac");
    }

    render() {
        let text = {
            key: "tac_text",
            link: <a href="https://www.deutsche-rentenversicherung.de/BadenWuerttemberg/DE/Service/Footer/Datenschutz/datenschutz_node.html">{I18n.translate("tac_link")}</a>
        };

        return (
            <div id="form" className="form">
                <ImageHeader />
                <div id="form-content">
                    <div className="header"><h1>{I18n.translate("tac_header")}</h1></div>
                    <div id="form-survey">
                        <I18n.span text={text}></I18n.span>
                    </div>
                </div>
            </div>
        )
    }

}

export default Tac;