import ConfigService from './ConfigService';
import PubSubService from "./PubSubService";
import React from 'react';
import ReactDOM from 'react-dom';
import App from '../components/App';
import rainbowSDK from 'rainbow-web-sdk';
import Logger from './LoggingService';

class RainbowSDKService {

    async start() {
        let that = this;
        Logger.info("[RainbowSDKService] starting");
        let onLoaded = () => {
            Logger.info("[RainbowSDKService] SDK loaded");
            rainbowSDK.config.useNewModels(true);
            rainbowSDK.initialize("", "").then(() => {
                Logger.info("[RainbowSDKService] SDK started correctly");
            }).catch((err) => {
                Logger.error(["[RainbowSDKService] SDK not started !!!", err]);
            });
        };

        let onReady = () => {
            Logger.info("[RainbowSDKService] SDK ready");
            ReactDOM.render(<App />, document.querySelector('#app'));
        };

        let onConnectionChanged = (state) => {
            if (rainbowSDK.connection.RAINBOW_CONNECTIONDISCONNECTED === state) {
                Logger.info("[RainbowSDKService] SDK has been disconnected");
                that._isConnected = false;
            } else if (rainbowSDK.connection.RAINBOW_CONNECTIONCONNECTED === state) {
                Logger.info("[RainbowSDKService] SDK is connected");
                that._isConnected = true;
            }
        };

        let onConnectionStateChanged = (event, status) => {
            Logger.info(["[RainbowSDKService] onConnectionStateChanged  - ", status, event]);
            PubSubService.emit("sdk-" + status);
        };

        let onStarted = (status) => {
            Logger.info(["[RainbowSDKService] onStarted ", status]);
            PubSubService.emit("sdk-started");
        };

        let onStopped = (status) => {
            Logger.info(["[RainbowSDKService] onStopped ", status]);
            PubSubService.emit("sdk-stopped");
        };


        let onWebRTCCallChanged = (event) => {
            console.log(event);
            PubSubService.emit("webRTC-event", event);
        };

        let onWebRTCTrackChanged = (event) => {
            PubSubService.emit("webRTCTrack-event", event);
        };

        document.addEventListener(rainbowSDK.RAINBOW_ONREADY, onReady);
        document.addEventListener(rainbowSDK.RAINBOW_ONLOADED, onLoaded);
        document.addEventListener(rainbowSDK.RAINBOW_ONCONNECTIONSTATECHANGED, onConnectionChanged);
        document.addEventListener(rainbowSDK.connection.RAINBOW_ONCONNECTIONSTATECHANGED, onConnectionStateChanged);
        document.addEventListener(rainbowSDK.connection.RAINBOW_ONSTARTED, onStarted);
        document.addEventListener(rainbowSDK.connection.RAINBOW_ONSTOPPED, onStopped);

        document.addEventListener(rainbowSDK.webRTC.RAINBOW_ONWEBRTCCALLSTATECHANGED, onWebRTCCallChanged);
        document.addEventListener(rainbowSDK.webRTC.RAINBOW_ONWEBRTCTRACKCHANGED, onWebRTCTrackChanged);


        rainbowSDK.load();
    }

    stop() {
    }

    async loginWithToken(token) {
        ConfigService.initialize();
        if(rainbowSDK.connection.getState() === rainbowSDK.connection.RAINBOW_CONNECTIONCONNECTED){
            Logger.info("[RainbowSKDService] Already logged in, signing out")
            await rainbowSDK.connection.signout();
        }

        return await rainbowSDK.connection.signinOnRainbowHostedWithToken(token, ConfigService.config.rainbow.server.host);
    }


    logout() {
        return rainbowSDK.connection.signout();
    }

    canMakeAudioVideoCall() {
        return rainbowSDK.webRTC.canMakeAudioVideoCall();
    }

    release(call) {
        rainbowSDK.webRTC.release(call.id);
    }

    removeVideoFromCall(call) {
        rainbowSDK.webRTC.removeVideoFromCall(call);
    }

    addVideoToCall(call) {
        rainbowSDK.webRTC.addVideoToCall(call);
    }

    muteCallAudio(call) {
        let conversation = rainbowSDK.conversations.getConversationById(call.conversationId);
        rainbowSDK.webRTC.muteAudioCall(conversation);
    }

    unmuteCallAudio(call) {
        let conversation = rainbowSDK.conversations.getConversationById(call.conversationId);
        rainbowSDK.webRTC.unmuteAudioCall(conversation);
    }

    hideLocalVideo() {
        rainbowSDK.webRTC.hideLocalVideo();
    }

    showLocalVideo() {
        rainbowSDK.webRTC.showLocalVideo();
    }

    hideRemoteVideo(call) {
        rainbowSDK.webRTC.hideRemoteVideo(call.id);
    }

    showRemoteVideo(call) {
        rainbowSDK.webRTC.showRemoteVideo(call.id);
    }
}

export default new RainbowSDKService();