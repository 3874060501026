import React from 'react';
import I18n from "i18n-react";
import moment from 'moment';
import ImageHeader from './ImageHeader';

export default class CallEnd extends React.Component {

    constructor(props) {
        super(props);
        this.closeAndRedirect = this.closeAndRedirect.bind(this);
        this.displayBackButton = this.getBackButton.bind(this);
    }

    closeAndRedirect(event) {
        window.location.href = "https://www.deutsche-rentenversicherung.de";
    }

    getBackButton() {
        let appointment = this.props.appointment;
        let now = moment();
        let end = moment(appointment.end);

        if (now.isBefore(end)) {
            return (
                <button className="btn" id="returnToStart" onClick={this.props.returnToStart} title={I18n.translate("back_to_start")}>
                    {I18n.translate("back_to_start")}
                </button>
            )
        }

    }

    render() {
        return (
            <div className="container">
                <ImageHeader />
                <div className="header">
                    <h1>{I18n.translate("call_end_title")}</h1>
                </div>

                <div className="button-container">
                    {this.getBackButton()}
                </div>
            </div>
        );
    }
}
