import ConfigService from './ConfigService';
import Logger from './LoggingService';

const callEndpoint = async (endpoint, method = "get", body) => {
    let response = await fetch(ConfigService.config.chatService.url + '/' + endpoint, {
        method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    let data;
    try {
        data = await response.json();
    } catch (e) { }
    return {
        data,
        success: response.ok
    }
}

const createQueryString = parameters => {
    return '?' + Object.entries(parameters)
        .reduce((accumulator, [key, value]) => accumulator + `&${key}=${JSON.stringify(value)}`, '')
        .replace(/"/g, '')
        .slice(1);
}

class APIService {

    async getSession(loginData) {
        let response = await callEndpoint("session", "post", loginData);
        if (response.success) {
            return response.data;
        } else {
            Logger.error(["[ApiService] Error creating session => ", response.data], true);
            throw response.data;
        }
    }

    async findAdvisor(region) {
        let queryString = createQueryString({region});
        let url = "findAdvisor" + queryString;

        let response = await callEndpoint(url);
        if (response.success) {
            return response.data;
        } else {
            Logger.error(["[ApiService] Error searching advisor => ", response.data], true);
            throw response.data;
        }
    }

    async log(logData) {
        let response = await callEndpoint('log', 'put', logData);
        return response.success;
    }

    async config(){
        let response = await callEndpoint("client-config");
        if(response.success){
            return response.data;
        }else{
            Logger.error(["[ApiService] Error loading configuration from server", response.data]);
            throw response.data;
        }
    }

    async createMapping(mappingData){
        let response = await callEndpoint("create-mapping", "put", mappingData);
        return response.success;
    }
}


export default new APIService();