import I18n from "i18n-react";
import Logger from './LoggingService';

class I18nService {

    constructor(){
        this.initialised = false;
    }

    getUsedLanguage(){
        let language = navigator.language || "de"
        if (language.length > 2) {
            language = language.split("-")[0];
            language = language.split("_")[0];
        }  
        if(!this.getLanguages().includes(language)){
            language = "de";
        }
        Logger.info(["[I18nService] language used :", language]);
        return language;
    }

    getLanguages(){
        return ["de"];
    }    

    getTexts(){
        return I18n.texts;
    }

    async init(){
        if(this.initialized){
            return;
        }
        let locale = this.getUsedLanguage()

        let request = new Request(`/locales/${locale}.json`, {
            "headers": new Headers({
                "Content-Type": "application/json"
            }),
            "method": "GET"
        });

        let response = await fetch(request);
        let data = await response.json();
        
        if(response.ok){
            Logger.info("[I18nService] config file successfully loaded");
            Logger.info("[I18nService] initialized!");
            I18n.setTexts(data);
            this.initialized = true;
            return data;
        }else{
            Logger.error("[I18nService] error when loading the config file");
            throw data;
        }
    }
 }


 export default new I18nService();