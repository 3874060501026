import React from 'react';
import Unavailability from './Unavailability';
import APIService from '../services/APIService';
import RainbowSDKService from '../services/RainbowSDKService';
import PubSubService from '../services/PubSubService';
import ConfigService from '../services/ConfigService';
import StartingPage from "./StartingPage";
import SearchingPage from "./SearchingPage";
import OneToOneSession from "./OneToOneSession";
import Tac from './Tac';
import Contacts from './Contacts';
import HardwareTest from './HardwareTest';
import CallEnd from "./CallEnd";
import Legal from './Legal';
import MediaTestOngoing from './media/MediaTestOngoing';
import MediaTestResult from './media/MediaTestResult';
import MediaTest from '../MediaTest';
import rainbowSDK from 'rainbow-web-sdk';
import Logger from "../services/LoggingService";

let STATES = Object.freeze({
    start: 1,
    preparing: 2,
    calling: 3,
    unavailable: 4,
    finished: 5,
    media_check: 6,
    media_result: 7,
    tac: 8,
    contact: 9,
    hardware_test: 10
});

const routes = {
    '/': STATES.start,
    '/tac': STATES.tac,
    '/contact': STATES.contact,
    '/hardwaretest': STATES.hardware_test
}
const defaultRoute = '/';

const parseRoute = () => {
    const route = window.location.hash.slice(1) || defaultRoute;
    return routes[route] || STATES.start;
}

class App extends React.Component {

    constructor(props) {
        super(props);

        let params = new URLSearchParams(window.location.search);
        let id = params.get(ConfigService.config.idUrlParam);
        if (id == null) {
            id = "";
        }

        const initialView = parseRoute();

        this.state = {
            appState: initialView,
            connected: false,
            advisors: [],
            currentAdvisor: undefined,
            loginData: {
                vsnr: "",
                terminnr: id,
                firstname: "",
                lastname: "",
                region: "-",
                termsAccepted: false,
                date: undefined
            },
            media_error: false,
            media_results: undefined,
            error: undefined,
            vsnrError: false,
            terminnrError: false,
            appointment: null,
            showSearchingText: false
        };

        this.onStart = this.onStart.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onRainbowDisconnected = this.onRainbowDisconnected.bind(this);

        this.onCallEstablished = this.onCallEstablished.bind(this);
        this.onCallReleased = this.onCallReleased.bind(this);
        this.onCallFinished = this.onCallFinished.bind(this);

        this.onBackToStart = this.onBackToStart.bind(this);
        this.onMediaCheck = this.onMediaCheck.bind(this);

        this.queryAdvisor = this.queryAdvisor.bind(this);
        this.onHashChange = this.onHashChange.bind(this);
        this.login = this.login.bind(this);

        this.searchTries = 0;
        this.callTries = 0;

    }

    componentDidMount() {
        window.addEventListener("hashchange", this.onHashChange);
    }

    onHashChange() {
        this.setState({
            appState: parseRoute()
        });
    }

    async onMediaCheck(loginData) {
        this.setState({
            appState: STATES.media_check,
            loginData
        });
        let mediaCheckResults = await MediaTest.checkAvailability(rainbowSDK.webRTC);
        this.setState({
            appState: STATES.media_result,
            media_results: mediaCheckResults,
            media_error: false
        });
    }

    async login(data) {
        let loginData = {
            terminnr: data.terminnr,
            firstname: data.firstname,
            lastname: data.lastname,
            date: new Date()
        }
        Logger.debug("[App] Init session :", loginData);
        PubSubService.addListener(PubSubService.SDK_DISCONNECTED, this.onRainbowDisconnected);
        try {
            let session = await APIService.getSession(loginData);
            Logger.debug("[App] Session established", true)
            this.setState({
                appointment: session.appointment
            })
            await RainbowSDKService.loginWithToken(session.token);
            Logger.debug("[App] Logged with token ");
            if (this.state.appState === STATES.preparing) {
                this.setState({ connected: true, error: "ok" });
                this.searchTextTimeout = setTimeout(() => this.setState({
                    showSearchingText: true
                }), ConfigService.config.chatService.callAgentTextDelayMilliseconds)
                this.queryAdvisor()
            }
        } catch (error) {
            Logger.error(["[App] Error logging in", error], true);
            this.setState({
                error: error,
                appState: STATES.start
            })
        }
    }

    async queryAdvisor() {
        try {
            let region = this.state.appointment.region;
            let advisor = await APIService.findAdvisor(region);
            Logger.info("[App] Advisor found:", true);
            this.setState({
                appState: STATES.calling,
                currentAdvisor: advisor.id
            });
        } catch (error) {
            Logger.error(["[App] Error finding advisor", error], true);
            Logger.debug("[APP] Retrying...");
            if (++this.searchTries < ConfigService.config.chatService.findAgentTryCount) {
                this.timeout = setTimeout(this.queryAdvisor, ConfigService.config.chatService.findAgentDelayMilliseconds);
            } else {
                Logger.error("[App] No Advisor found", true)
                this.setState({
                    appState: STATES.unavailable
                });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.onHashChange);
        PubSubService.removeListener(PubSubService.SDK_DISCONNECTED, this.onRainbowDisconnected);
    }

    async onStart(loginData) {
        Logger.info("[App] Form submitted", true);
        this.setState({
            appState: STATES.media_check,
            loginData
        })
        let mediaCheckResults = await MediaTest.checkAvailability(rainbowSDK.webRTC);
        if (mediaCheckResults.allOk) {
            this.setState({
                appState: STATES.preparing
            });
            this.login(loginData);
        } else {
            Logger.error(["[App] Mediatest failed", mediaCheckResults], true);
            this.setState({
                appState: STATES.media_result,
                media_results: mediaCheckResults,
                media_error: true
            });
        }
    }

    onCancel() {
        Logger.info("[App] Cancelled by user", true)
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (this.searchTextTimeout) {
            clearTimeout(this.searchTextTimeout);
        }
        this.searchTries = 0;


        this.setState({
            appState: STATES.start,
            error: undefined,
            showSearchingText: false
        });
    }

    async onCallEstablished() {
        Logger.info("[App] Connection established")
        let jid = this.state.currentAdvisor;
        let terminnr = this.state.loginData.terminnr;
        let end = this.state.appointment.end;

        let callData = { jid, terminnr, end };

        //APIService.createMapping(callData);
    }

    onCallReleased(endedByClient) {
        if (endedByClient) {
            Logger.info("[App] Released by user", true)
            this.setState({
                appState: STATES.start
            });
        } else if (++this.callTries < ConfigService.config.chatService.callAgentTryCount) {
            Logger.error("[App] Advisor didn't pick up. Retrying...", true)
            this.setState({
                appState: STATES.preparing
            });
            this.queryAdvisor();
        } else {
            Logger.info("[App] Can't reach advisor", true)
            this.setState({
                appState: STATES.unavailable
            });
        }
        /*} else {
            this.callTries++;
            if (this.callTries > this.state.advisors.length - 1) {
                this.setState({
                    appState: STATES.unavailable
                });
            } else {
                this.setState({
                    currentAdvisor: this.state.advisors[this.callTries]
                })
            }
        }*/
    }

    onCallFinished() {
        Logger.info("[App] Called finished", true);
        this.setState({
            appState: STATES.finished
        });
    }

    onRainbowDisconnected(event, bubble) {
        Logger.info("[App] Rainbow server disconnected");
        this.setState({ connected: false });
    }

    onBackToStart() {
        if (this.searchTextTimeout) {
            clearTimeout(this.searchTextTimeout);
        }
        this.searchTries = 0;

        this.setState({
            appState: STATES.start,
            error: undefined,
            showSearchingText: false
        })
    }

    getCurrentView() {
        switch (this.state.appState) {
            case STATES.start:
                return <StartingPage
                    onStart={this.onStart}
                    onMediaCheck={this.onMediaCheck}
                    loginData={this.state.loginData}
                    error={this.state.error}
                />;
            case STATES.preparing:
                return <SearchingPage
                    onCancel={this.onCancel}
                    connected={this.state.connected}
                    showText={this.state.showSearchingText}
                />;
            case STATES.calling:
                return <OneToOneSession
                    agent={this.state.currentAdvisor}
                    connected={this.state.connected}
                    showText={this.state.showSearchingText}
                    onCancel={this.onCancel}
                    onCallReleased={this.onCallReleased}
                    onCallFinished={this.onCallFinished}
                    onCallEstablished={this.onCallEstablished}
                />
            case STATES.unavailable:
                return <Unavailability returnToStart={this.onBackToStart} />;
            case STATES.finished:
                return <CallEnd returnToStart={this.onBackToStart} appointment={this.state.appointment} />;
            case STATES.media_check:
                return <MediaTestOngoing />;
            case STATES.media_result:
                return <MediaTestResult
                    returnToStart={this.onBackToStart}
                    isError={this.state.media_error}
                    showBackButton={true}
                    results={this.state.media_results} />;
            case STATES.tac:
                return <Tac />
            case STATES.contact:
                return <Contacts />
            case STATES.hardware_test:
                return <HardwareTest />
        }
    }

    render() {
        let currentView = this.getCurrentView();
        return (
            <div id="app">
                {currentView}
                <Legal newTab={true} />
            </div>
        );
    }
}

export default App;