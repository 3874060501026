import React from "react";
import PropTypes from "prop-types";
import RainbowSDKService from "../../services/RainbowSDKService";
import I18n from "i18n-react";

export default class CallControl extends React.Component {
    static get propTypes() {
        return {
            "callDisabled": PropTypes.bool,
            "currentCall": PropTypes.object,
            "hasLocalVideo": PropTypes.bool,
            "isInCall": PropTypes.bool
        };
    }

    constructor(props) {
        super(props);
        this.releaseCall = this.releaseCall.bind(this);
    }

    releaseCall(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        console.log("[CallControl] releaseCall");
        RainbowSDKService.release(this.props.currentCall);
    }

    render() {
        console.log("[CallControl] CallControl - render - begin");
        let callButton = null;
        if (this.props.isInCall) {
            callButton = <button id="releaseCallButton" className="callControl-btn" onClick={this.releaseCall} title={I18n.translate("end_call")}><i className="fas fa-phone-slash"></i></button>
        }

        return (
            <span>
                {callButton}
            </span>
        );
    }

}
