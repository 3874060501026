let checkVideo = async () => {
    return checkMediaDevices({
        "audio": false,
        "video": true
    });
}

let checkAudio = async () => {
    return await checkMediaDevices({
        "audio": true,
        "video": false
    });
}

let checkWebRTC = (webRTC) => {
    let result = {};
    result["ok"] = webRTC.canMakeAudioVideoCall();
    if (!result.ok) {
        result["error"] = "NotFoundError"
    }
    return result;
}

let checkMediaDevices = async (config) => {
    try {
        let stream = await navigator.mediaDevices.getUserMedia(config);
        stream.getTracks().forEach(track => track.stop());
        return { ok: true };
    } catch (e) {
        let error = e.name;
        if (!error) {
            error = "other"
        }
        return { ok: false, error: error };
    }
}

let checkAvailability = async (webRTC) => {
    let results = {};

    results["video"] = await checkVideo();
    results["audio"] = await checkAudio();
    results["webrtc"] = checkWebRTC(webRTC);

    let allOk = true;
    for (let key in results) {
        if (!results[key].ok) {
            allOk = false;
        }
    }

    return { results, allOk };
}

export default { checkAvailability };