import React from 'react';
import PropTypes from 'prop-types'
import I18n from "i18n-react";
import ImageHeader from '../ImageHeader';

export default class MediaTestResult extends React.Component {

    static get propTypes() {
        return {
            showBackButton: PropTypes.bool,
            isError: PropTypes.bool,
            returnToStart: PropTypes.func,
            results: PropTypes.object
        }
    }

    constructor(props) {
        super(props);
        this.getResults = this.getResults.bind(this);
        this.getButton = this.getButton.bind(this);
    }


    getResultText(result) {
        if (result.ok) {
            return I18n.translate("media_ok");
        } else {
            switch (result.error) {
                case "NotFoundError":
                    return I18n.translate("media_unavailable");
                case "NotAllowedError":
                    return I18n.translate("media_no_permission");
                default:
                    return I18n.translate("media_other_error");
            }
        }
    }

    getResults() {
        let results = this.props.results.results;
        let rows = [];
        for (let key in results) {
            let result = results[key];
            let label = I18n.translate("media_test_" + key);
            let text = this.getResultText(result);
            rows.push((
                <tr key={rows.length}>
                    <th>{label}</th>
                    <td>{text}</td>
                </tr>
            ));
        }
        return rows;
    }

    getButton() {
        if (this.props.showBackButton) {
            return (
                <div className="button-container">
                    <button className="btn" id="returnToStart" onClick={this.props.returnToStart} title={I18n.translate("back_to_start")}>
                        {I18n.translate("back_to_start")}
                    </button>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="container">
                <ImageHeader />
                <div className="header">
                    <h1>{I18n.translate(this.props.isError ? "media_error_title" : "media_test_title")}</h1>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <th>{I18n.translate("media_test_overall")}</th>
                            <td>{I18n.translate(this.props.results.allOk ? "media_ok" : "media_not_ok")}</td>
                        </tr>
                        {this.getResults()}
                    </tbody>
                </table>

                {this.getButton()}
            </div>
        );
    }
}