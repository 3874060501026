import React from 'react';
import I18n from "i18n-react";

class Legal extends React.Component {
    render() {
        let target = this.props.newTab ? "_blank": "_self";
        return (
            <div className="legal">
                <a target={target} rel="noopener noreferrer" href="/FAQ.pdf">{I18n.translate("faq")}</a>
                |
                <a target={target} rel="noopener noreferrer" href="/#/contact" >{I18n.translate("legal_contact")}</a>
                |
                <a target={target} rel="noopener noreferrer" href="https://www.deutsche-rentenversicherung.de/BadenWuerttemberg/DE/Service/Footer/Impressum/impressum_node.html">{I18n.translate("legal_impressum")}</a>
                |
                <a target={target} rel="noopener noreferrer" href="https://www.deutsche-rentenversicherung.de/BadenWuerttemberg/DE/Service/Footer/Datenschutz/datenschutz_node.html">{I18n.translate("legal_data")}</a>
            </div>
        )
    }
}

export default Legal;