import React from 'react';
import ReactDOM from 'react-dom';
import I18n from 'i18n-react';
import App from './app/components/App';
import rainbowSDK from 'rainbow-web-sdk';
import RainbowSDKService from './app/services/RainbowSDKService';
import ConfigService from './app/services/ConfigService';
import I18nService from './app/services/I18nService';
import Logger from './app/services/LoggingService';


document.addEventListener('DOMContentLoaded', async () => {
  Logger.info(["[Init] SDK Version ", rainbowSDK.version()]);
  await ConfigService.initialize();
  ConfigService.start();
  await ConfigService.loadFromServer();
  await I18nService.init();
  Logger.debug(["[Init] Lokalisierung: ", I18n.texts])
  document.title = I18n.translate("title");
  Logger.debug(['[Init] Config: ', ConfigService.config]);
  RainbowSDKService.start();
  
  /* Add window object to allow debugging from the console */
  window.rainbowSDK = rainbowSDK;

  ReactDOM.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>,
    document.getElementById('app'))
});