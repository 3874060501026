import React from "react";
import I18n from "i18n-react";
import rainbowSDK from 'rainbow-web-sdk';
import MediaTestOngoing from './media/MediaTestOngoing';
import MediaTestResult from './media/MediaTestResult';
import MediaTest from '../MediaTest';


class HardwareTest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            testFinished: false,
            testResult: undefined
        }
        this.checkMedia = this.checkMedia.bind(this);
    }

    componentDidMount() {
        document.title = I18n.translate("title_hardware");
        this.checkMedia();
    }


    async checkMedia() {
        let mediaCheckResults = await MediaTest.checkAvailability(rainbowSDK.webRTC);
        this.setState({
            testFinished: true,
            testResults: mediaCheckResults,
        });
    }


    render() {
        if (this.state.testFinished) {
            return <MediaTestResult isError={false} showBackButton={false} results={this.state.testResults} />
        } else {
            return <MediaTestOngoing />
        }
    }



}

export default HardwareTest;