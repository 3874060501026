import APIService from './APIService';

class Logger {

    constructor() {
        this.debug = this.debug.bind(this);
        this.info = this.info.bind(this);
        this.error = this.error.bind(this);
    }

    setCorrelationId(correlationId){
        this.correlationId = correlationId;
    }

    getCorrelationId(){
        return this.correlationId;
    }

    debug(message, remote, agent) {
        this.log('debug', message, remote, agent);
    }

    info(message, remote, agent) {
        this.log('info', message, remote, agent);
    }

    error(message, remote, agent) {
        this.log('error', message, remote, agent);
    }

    log(level, message, remote = false, agent = false) {
        const fun = console[level];
        if (fun) {
            if (Array.isArray(message)) {
                fun(...message);
            } else {
                fun(message);
            }
        } else {
            console.error("Unknown log level:", level);
        }

        if (remote === true) {
            const data = {
                level,
                agent,
                message
            }
            APIService.log(data);
        }
    }

}

export default new Logger();