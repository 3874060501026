import React from 'react';
import PropTypes from "prop-types";
import MediaController from './MediaController';
import I18n from "i18n-react";
import Logger from '../services/LoggingService';

export default class OneToOneSession extends React.Component {

    static get propTypes() {
        return {
            "agent": PropTypes.string.isRequired,
            "connected": PropTypes.bool.isRequired,
            "showText": PropTypes.bool.isRequired,
            "onCancel": PropTypes.func.isRequired,
            "onCallReleased": PropTypes.func.isRequired,
            "onCallFinished": PropTypes.func.isRequired,
            "onCallEstablished": PropTypes.func.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            "messages": [],
            "message": "",
            "closed": false,
            "error": false,
            "inCall": false,
            "callFinished": false
        };

        this.onResize = this.onResize.bind(this);
        this.onCallEvent = this.onCallEvent.bind(this);
        this.onCallFinished = this.onCallFinished.bind(this);
        this.HEIGHT_TO_REMOVE = 110; // (init)60+50
        this.DEFAULT_VIDEO_WIDTH = 700;
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
        this.initialHeight = window.outerHeight;
        this.initialWidth = window.outerWidth;
    }

    onResize() {
        this.forceUpdate();
    }

    onCallEvent(isCall) {
        Logger.info(["[Room] on callActivated ", isCall])
        this.setState({ "inCall": isCall });
        if (isCall) {
            Logger.info(["[Room] resize ", window.outerWidth + this.DEFAULT_VIDEO_WIDTH, " -  ", window.outerHeight]);
            window.resizeTo(window.outerWidth + this.DEFAULT_VIDEO_WIDTH, window.outerHeight);
            this.props.onCallEstablished();
        } else {
            Logger.info(["[Room] resize ", this.initialWidth, " -  ", this.initialHeight]);
            window.resizeTo(this.initialWidth, this.initialHeight);
        }
    }

    onCallFinished(isCall) {
        Logger.info(["[Room] on callActivated ", isCall]);
        this.props.onCallFinished();
    }


    render() {
        return (
            <div>
                <div id="form" style={{ "display": this.state.inCall || this.state.callFinished ? "none" : "block" }}>
                    <div id="form-header">
                        <img src="img/icon_rainbow-bw.svg" alt="" className="topFormLogo" />
                    </div>
                    <div id="form-content">
                        <p className="notice">{I18n.translate("calling_agent")}</p>
                        <p className="notice" style={{ "visibility": this.props.showText ? "visible" : "hidden" }}>{I18n.translate("calling_agent_text")}</p>
                        <div className="form-survey-submit button-container">
                            <button onClick={this.props.onCancel} className="btn btn-default">{I18n.translate("cancel")}</button>
                        </div>
                    </div>
                </div>
                <MediaController
                    agent={this.props.agent}
                    onCallEvent={this.onCallEvent}
                    onCallFinished={this.onCallFinished}
                    onCallReleased={this.props.onCallReleased}
                />
            </div>
        );
    }
}