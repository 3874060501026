import React from "react";
import PropTypes from "prop-types";
import rainbowSDK from 'rainbow-web-sdk';
import RainbowSDKService from "../services/RainbowSDKService";
import PubSubService from "../services/PubSubService";
import MediaService from "../services/MediaService";
import CallControl from "./CallControl/CallControl";
import WebRTCCall from "./WebRTCCall";
import Logger from "../services/LoggingService";

export default class MediaController extends React.Component {

    static get propTypes() {
        return {
            "agent": PropTypes.string,
            "onCallEvent": PropTypes.func.isRequired,
            "onCallFinished": PropTypes.func.isRequired,
            "onCallReleased": PropTypes.func.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            "currentCall": null,
            "errorMessage": "",
            "hasLocalVideo": false,
            "hasRemoteVideo": false,
            "isInCall": false,
            "videoSupported": true,
            "callState": "noCall"
        };
        this.remoteTrack = 0;
        this.endedByClient = false;
        this.alreadyReleased = false;
        this.listenWebRTCEvents = this.listenWebRTCEvents.bind(this);
        this.callAContact = this.callAContact.bind(this);
        this.listenWebRTCTrackChanged = this.listenWebRTCTrackChanged.bind(this);
        this.hideLocalVideo = this.hideLocalVideo.bind(this);
        this.showLocalVideo = this.showLocalVideo.bind(this);
        this.hideRemoteVideo = this.hideRemoteVideo.bind(this);
        this.showRemoteVideo = this.showRemoteVideo.bind(this);
    }

    async callAContact(id) {
        Logger.info(["[Media] Calling contact:", id]);
        var res = await rainbowSDK.webRTC.callInVideo(id);
        if (res.code === rainbowSDK.OK) {
            Logger.info("[Media] WebRTC Call state initiated ");
        } else {
            Logger.info(["[Media] Result ", res]);
        }
    };

    listenWebRTCEvents(event) {
        let call = event.detail;
        Logger.info(["[ANRUF] WebRTC Call state changed to " + call.status.value, call]);
        switch (call.status.value) {
            case "incommingCall":
                Logger.info("[Media] incoming Call ");
                this.setState({ "callState": "alerting" });
                break;

            case "active":
                Logger.info("[Media] active ");
                this.setState({ "isInCall": true });
                this.setState({ "callState": "inCall" });
                this.showLocalVideo();
                this.showRemoteVideo(call);
                this.props.onCallEvent(true);
                break;

            case "Unknown":
                Logger.info("[Media] unknown ");
                this.hideLocalVideo();
                try {
                    this.hideRemoteVideo(call);
                } catch (error) {
                    Logger.info(["[Call] Couldn't hide remote video. Call released? ", error]);
                }
                this.setState({ "hasLocalVideo": false });
                this.setState({ "callState": "noCall" });
                this.props.onCallEvent(false);
                if (this.state.isInCall) {
                    this.props.onCallFinished();
                } else {
                    Logger.info(`[Call] Call Released, by client: ${this.endedByClient}`)
                    this.alreadyReleased = true;
                    this.props.onCallReleased(this.endedByClient);
                }
                this.setState({ "isInCall": false });
                break;

            default:
                Logger.debug("[Media] Nothing to do with that event...");
                break;
        }
        this.setState({ "currentCall": call });
    }

    listenWebRTCTrackChanged(event) {
        let call = event.detail;
        Logger.info("[Media] WebRTC Track changed local|remote " + call.localMedia + "|" + call.remoteMedia);
        this.showRemoteVideo(call);
        /*
        if (call.remoteMedia === 3) {
            this.showRemoteVideo(call);
        } else {
            this.hideRemoteVideo(call);
        }*/

        if (call.localMedia === 3) {
            this.showLocalVideo();
        } else {
            this.hideLocalVideo();
        }
    }

    showRemoteVideo(call) {
        Logger.info("[Media] Display remote video");
        RainbowSDKService.showRemoteVideo(call);
        this.setState({ "hasRemoteVideo": true });
    }

    hideRemoteVideo(call) {
        Logger.info("[Media] Hide remote video");
        RainbowSDKService.hideRemoteVideo(call);
        this.setState({ "hasRemoteVideo": true });
    }

    hideLocalVideo() {
        Logger.info("[Media] Hide local video");
        RainbowSDKService.hideLocalVideo();
        this.setState({ "hasLocalVideo": false });
    }

    showLocalVideo() {
        Logger.info("[Media] Show local video");
        RainbowSDKService.showLocalVideo();
        this.setState({ "hasLocalVideo": true });
    }

    componentWillUnmount() {
        if (this.state.currentCall && !this.alreadyReleased) {
            this.endedByClient = true;
            try {
                RainbowSDKService.release(this.state.currentCall);
            } catch (error) {
                Logger.info(["[Call] Couldn't release call. Call already released? ", error]);
            }
        }
        PubSubService.removeListener("webRTC-event", this.listenWebRTCEvents);
        PubSubService.removeListener("app-presence-event", this.onPresenceEvent);
        PubSubService.removeListener("webRTCTrack-event", this.listenWebRTCTrackChanged);
    }

    async componentDidMount() {
        PubSubService.addListener("app-presence-event", this.onPresenceEvent);
        PubSubService.addListener("webRTC-event", this.listenWebRTCEvents);
        PubSubService.addListener("webRTCTrack-event", this.listenWebRTCTrackChanged);
        this.endedByClient = false;
        Logger.info("[Media]  Did mount");
        if (this.props.agent) {
            this.callAContact(this.props.agent);
        }
        MediaService.changeCamera();
        MediaService.changeMicrophone();
        MediaService.changeSpeakerDevice();
    }

    render() {
        const styles = {
            "mediaArea": {
                "height": this.state.isInCall ? window.innerHeight : "0px",
                "width": this.state.isInCall ? "100%" : "0px",
                "float": "right",
                "overflow": "hidden"
            },
            "mediaController": {
                "width": this.state.isInCall ? "100%" : "0px",
            }
        };
        return (
            <div className="mediaArea" style={styles.mediaArea}>
                <div id="topAreaMedia" className="topArea">
                </div>

                <WebRTCCall callState={this.state.callState} />
                <div className="mediaController" style={styles.mediaController}>
                    <CallControl
                        callDisabled={!this.state.videoSupported}
                        hasLocalVideo={this.state.hasLocalVideo}
                        isInCall={this.state.isInCall}
                        currentCall={this.state.currentCall}
                    />
                </div>
                <div id="footerMedia" className="footer">
                </div>
            </div>
        );

    }
}
