import React from 'react';
import I18n from "i18n-react";
import ImageHeader from '../ImageHeader';

export default class MediaTestOngoing extends React.Component {

    render() {
        return (
            <div id="form" className="form">
                <ImageHeader />
                <div id="form-content">
                    <p className="notice">{I18n.translate("checking_media")}</p>
                </div>
            </div>
        )
    }

}