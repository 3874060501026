import React from 'react';
import I18n from "i18n-react";
import ImageHeader from './ImageHeader';

export default class Unavailability extends React.Component {

    constructor(props) {
        super(props);
        this.closeAndRedirect = this.closeAndRedirect.bind(this);
    }

    closeAndRedirect(event) {
        window.location.href = "https://www.deutsche-rentenversicherung.de";
    }

    render() {
        return (
            <div className="container">
                <ImageHeader />
                <div className="header">
                    <h1>{I18n.translate("unavailability_title")}</h1>
                </div>
                <p>{I18n.translate("unavailability_nobody")}</p>
                <div className="button-container">
                    <button className="btn" id="returnToStart" onClick={this.props.returnToStart} title={I18n.translate("back_to_start")}>
                        {I18n.translate("back_to_start")}
                    </button>
                    <button className="btn" id="closeAndRedirect" onClick={this.closeAndRedirect} title={I18n.translate("unavailability_contact_us")}>
                        {I18n.translate("unavailability_contact_us")}
                    </button>
                </div>
            </div>
        );
    }
}
