//import "whatwg-fetch";
//import "babel-polyfill";
import APIService from "./APIService";
import Logger from './LoggingService';

class ConfigService {

    constructor() {
        this._initialized = false;
        this._started = false;
        this.config = null;
        this.restServerUrl = null;
    }

    get hasBeenInitialized() {
        return this._initialized;
    }

    get hasBeenStarted() {
        return this._started;
    }

    get hasConfig() {
        return this.config !== null;
    }

    async initialize() {

        if (this._initialized) {
            return;
        }

        let request = new Request("/config.json", {
            "headers": new Headers({
                "Content-Type": "application/json"
            }),
            "method": "GET"
        });

        let response = await fetch(request);
        let data = await response.json();
        if (!response.ok) {
            Logger.error("[ConfigService] error when loading the config file");
            throw data;
        }

        Logger.info("[ConfigService] config file successfully loaded");
        Logger.info("[ConfigService] initialized!");
        this.config = data;
        this._initialized = true;
        return data;

    }

    async loadFromServer(){
        let config = await APIService.config();
        for (let key in config) {
            this.config[key] = config[key];
        }
    }

    start() {
        if (this._started) {
            Logger.info("[ConfigService] already started");
        } else {
            this._started = true;
            Logger.info("[ConfigService] started!");
        }
    }
}

export default new ConfigService();
