import React from "react";
import PropTypes from "prop-types";
import Logger from '../services/LoggingService';

export default class WebRTCCall extends React.Component {

    static get propTypes() {
        return {
            "callState": PropTypes.string.isRequired
        };
    }

    constructor(props) {
        super(props);
        this.videoHeight = 0;
        this.videoWidth = 0;
        this.areaHeight = 0;
        this.areaWidth = 0;
    }

    render() {
        const styles = {
            "mediaVideo": {
                "width": "100%",
                "display": "none",
                "overflow": "hidden"
            },
            "largeVideo": {
                "width": "100%",
                "height": "100%"
            },
            "miniVideo": {
                "float": "right",
                "top": "60px",
                "position": "absolute"
            }
        };

        if (this.refs.largeVideo) {
            let ratio = this.refs.mediaVideo.clientWidth / this.refs.mediaVideo.clientHeight;
            this.videoHeight = this.refs.largeVideo.clientHeight;
            this.videoWidth = this.refs.largeVideo.clientWidth;
            this.areaHeight = this.refs.mediaVideo.clientHeight;
            this.areaWidth = this.refs.mediaVideo.clientWidth;
            let croppingLeft = (this.areaHeight - this.videoHeight) / 2;
            let croppingTop = (this.areaWidth - this.videoWidth) / 2;

            styles.largeVideo = {
                "height": "100%",
                "width": "100%",
                "marginLeft": croppingTop + "px",
                "marginTop": croppingLeft + "px"
            }
            Logger.info(["Area    => ", this.areaHeight, " - ", this.areaWidth, " => ", ratio]);
            Logger.info(["Video   => ", this.videoHeight, " - ", this.videoWidth]);
            Logger.info(["Cropping   => ", croppingTop, " - ", croppingLeft]);
        }
        if (this.props.callState === "inCall") {
            Logger.info("[CallAnswerPanel] Display video");
            styles.mediaVideo.display = "block";
        }
        return (
            <div className="mediaVideo" style={styles.mediaVideo} ref="mediaVideo">
                <video id="minivideo" aria-label="minivideo" style={styles.miniVideo} autoPlay muted></video>
                <video id="largevideo" aria-label="largevideo" style={styles.largeVideo} ref="largeVideo" autoPlay></video>
                <video id="globalVideoTag" aria-label="globalVideoTag" autoPlay className="invisible"></video>
                <audio id="globalAudioTag" aria-label="globalAudioTag" autoPlay className="invisible"></audio>
            </div>
        );
    }
}
