import I18n from "i18n-react";
import React from "react";
import ImageHeader from './ImageHeader';

class Contacts extends React.Component {

    componentDidMount() {
        document.title = I18n.translate("title_contact");
    }

    regions() {
        let prefix = "contact_termin_mail_";
        //Konfigurierte Regions auslesen
        let regions = Object.keys(I18n.texts)
            .filter(text => text.startsWith(prefix))
            .map(text => text.substring(prefix.length));

        return regions.map(region => (
            <React.Fragment key={region}>
                <tr>
                    <th colSpan="2">
                        <h4 className="contact-header" >
                            {I18n.translate(`contact_termin_header_${region}`)}
                        </h4>
                    </th>
                </tr>
                <tr>
                    <th>
                        {I18n.translate("contact_label_phone")}
                    </th>
                    <td>
                        {I18n.translate(`contact_termin_phone_${region}`)}
                    </td>
                </tr>
                <tr className="contact-footer">
                    <th>
                        {I18n.translate("contact_label_mail")}
                    </th>
                    <td>
                        {this.mailLink(I18n.translate(`contact_termin_mail_${region}`))}
                    </td>
                </tr>
            </React.Fragment>
        ));
    }

    mailLink(mail) {
        let href = "mailto:" + mail;
        return <a href={href}>{mail}</a>
    }

    render() {
        let commonMail = I18n.translate("contact_common_mail");

        return (
            <div id="form" className="form">
                <ImageHeader />
                <div id="form-content">
                    <div className="header"><h1>{I18n.translate("contact_header")}</h1></div>
                    <div id="form-survey">
                        <fieldset title={I18n.translate("contact_termin_header")}>
                            <legend>{I18n.translate("contact_termin_header")}</legend>
                            <table>
                                <tbody>
                                    {this.regions()}
                                </tbody>
                            </table>
                        </fieldset>
                        <fieldset title={I18n.translate("contact_common_header")}>
                            <legend>{I18n.translate("contact_common_header")}</legend>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>
                                            {I18n.translate("contact_label_mail")}
                                        </th>
                                        <td>
                                            {this.mailLink(commonMail)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
                </div>
            </div>
        )
    }

}

export default Contacts;