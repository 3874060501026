import rainbowSDK from 'rainbow-web-sdk';

class MediaService {

    setSelectedMicrophone(micId) {
        localStorage.setItem("VISIO_MIC_ID", micId);
    }

    setSelectedSpeaker(speakerId) {
        localStorage.setItem("VISIO_SPEAKER_ID", speakerId);
    }

    setSelectedCamera(cameraId) {
        localStorage.setItem("VISIO_CAMERA_ID", cameraId);
    }

    getSelectedMicrophone() {
        let value = localStorage.getItem("VISIO_MIC_ID");
        return (value !== null ? value : "");
    }

    getSelectedSpeaker() {
        let value = localStorage.getItem("VISIO_SPEAKER_ID");
        return (value !== null ? value : "");
    }

    getSelectedCamera() {
        let value = localStorage.getItem("VISIO_CAMERA_ID");
        return (value !== null ? value : "");
    }

    changeMicrophone() {
        rainbowSDK.webRTC.useMicrophone(this.getSelectedMicrophone());
    }

    changeSpeakerDevice() {
        rainbowSDK.webRTC.useSpeaker(this.getSelectedSpeaker());
    }

    changeCamera() {
        rainbowSDK.webRTC.useCamera(this.getSelectedCamera());
    }
}


export default new MediaService();
