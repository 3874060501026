import React from "react";

class ImageHeader extends React.Component {
    render() {
        return (
            <div id="form-header">
                <img alt="" src="/img/icon_rainbow-bw.svg" className="topFormLogo" />
            </div>
        );
    }
}

export default ImageHeader;