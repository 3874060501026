import React from 'react';
import PropTypes from "prop-types";
import I18n from "i18n-react";

import ImageHeader from './ImageHeader';

export default class SearchingPage extends React.Component {

    static get propTypes() {
        return {
            "onCancel": PropTypes.func.isRequired,
            "connected": PropTypes.bool.isRequired,
            "showText": PropTypes.bool.isRequired
        };
    }

    constructor(props) {
        super(props);
        this.onCancel = this.onCancel.bind(this);
    }

    onCancel() {
        this.props.onCancel();
    }


    render() {
        return (
            <div id="form" className="form">
                <ImageHeader />
                <div id="form-content">
                    <p className="notice">{I18n.translate(this.props.connected ? "searching_agent" : "logging_in")}</p>
                    <p className="notice" style={{ "visibility": this.props.showText ? "visible" : "hidden" }}>{I18n.translate("calling_agent_text")}</p>
                    <div className="form-survey-submit button-container">
                        <button onClick={this.onCancel} className="btn btn-default">{I18n.translate("cancel")}</button>
                    </div>
                </div>
            </div>
        )
    }

}